var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"name":_vm.$attrs.name,"rules":_vm.$attrs.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid': errors[0] ? errors[0] : _vm.errorFn},attrs:{"name":_vm.$attrs.name,"rules":_vm.$attrs.rules,"options":_vm.options,"label":"label","placeholder":_vm.$attrs.placeholder},on:{"input":_vm.onChange,"search":_vm.onSearch},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v("\n                "+_vm._s(_vm.translations.not_found)+"\n            ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(_vm._s(_vm.translations.search))])]}},(_vm.$attrs.required)?{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":""}},'input',attributes,false),events))]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_vm._v(" "),_c('input',{attrs:{"type":"hidden","required":"","name":_vm.$attrs.name + '[value]'},domProps:{"value":_vm.selected ? _vm.selected.value : null}}),_vm._v(" "),_c('input',{attrs:{"type":"hidden","required":"","name":_vm.$attrs.name + '[label]'},domProps:{"value":_vm.selected ? _vm.selected.label : null}}),_vm._v(" "),_c('div',{staticClass:"form-text invalid-feedback"},[_vm._v("\n        "+_vm._s(errors[0] ? errors[0] : _vm.errorFn)+"\n    ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }