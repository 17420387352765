import {ValidationProvider, ValidationObserver, extend, localize} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
import es from 'vee-validate/dist/locale/es.json'

const locales = { es, en }

function loadLocale (code) {
    const locale = {
        code: locales[code].code,
        messages: locales[code].messages,
        names: window.App.translations.names,
        fields: window.App.translations.custom ?? [],
    }

    localize(code, locale)
}

loadLocale(window.App.locale)

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule])
})

Vue.component('validation-observer', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);
