<template>
    <validation-provider :name="$attrs.name" :rules="$attrs.rules"
                         v-slot="{ errors }">
        <label :for="$attrs.name" class="form-label-control" v-text="$attrs.label"></label>
        <input :id="$attrs.id"
               :type="$attrs.type"
               class="form-control"
               :placeholder="$attrs.placeholder"
               @input="value => $emit('input', value)"
               :class="{'is-invalid': errors[0] ? errors[0] : errorFn}"
               :name="$attrs.name"
               v-model="value"
               autofocus>

        <div class="form-text invalid-feedback" v-cloak>
            {{ errors[0] ? errors[0] : errorFn }}
        </div>
    </validation-provider>
</template>
<script>
export default {
    name: 'Input',
    props: {
        initialValue: {
            default: null
        },
        error: {
          default: null
        }
    },
    data() {
        return {
            value: this.initialValue,
            errorFn: this.error
        }
    },
    watch: {
        value() {
            this.errorFn = null
        }
    }

}
</script>
