<script>

export default {
    name: 'InvoiceForm',
    props: {
        bill: [],
        payment: [],
        errorMessage: ''
    },
    data() {
        return {
            value: null,
        }
    },
    computed:
        {
            totalFormatted() {
                return this.amountFormatted(this.value);
            }
        },
    methods: {
        amountFormatted(amount) {
            let formatter = new Intl.NumberFormat(this.locale, {
                style: 'currency',
                currency: 'COP',
                minimumFractionDigits: 2,
            });
            return formatter.format(amount);
        },
        validate({target}) {
            if (this.value <= 0 && (!this.payment || !this.payment.id)) {
                this.$root.$emit('flash', [{
                    type: 'error',
                    message: this.errorMessage,
                }])
                return;
            }
            target.submit()
            this.$loading(true)
        }
    }
}
</script>
