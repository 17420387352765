import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {
    position: 'bottom-right',
    duration: 5000,
    type: 'info',
    theme: 'outline'
});

export default {
    data: {
        toast: null,
        flash: window.App.flash ?? [],
    },

    mounted() {
        this.toast = this.$toasted
        this.flashExistingMessages()
        this.$root.$on('flash', (message) => {
            this.flash = message;
            this.flashExistingMessages()
        })
    },

    methods: {
        flashExistingMessages() {
            this.flash.forEach(({type, message}) => {
                this.setFlashMessage(message, {type: type})
            })
        },

        setFlashMessage(message, opts) {
            this.toast.show(message, {
                type: opts.type,
                timeLife: opts.timeout || 5000,
                closeBtn: opts.hasOwnProperty('dismissible') ? opts.dismissible : true,
            });
        },
    }
}
