<template>
    <validation-provider :name="$attrs.name" :rules="$attrs.rules"
                         v-slot="{ errors }">
        <v-select
            :name="$attrs.name"
            v-model="selected"
            @input="onChange"
            :rules="$attrs.rules"
            :class="{'is-invalid': errors[0] ? errors[0] : errorFn}"
            :options="options"
            label="label" :placeholder="$attrs.placeholder" @search="onSearch">
            <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                    {{ translations.not_found }}
                </template>
                <em style="opacity: 0.5;" v-else>{{ translations.search }}</em>
            </template>
            <template #search="{attributes, events}" v-if="$attrs.required">
                <input class="vs__search" required v-bind="attributes" v-on="events"/>
            </template>
        </v-select>
        <input type="hidden" required :name="$attrs.name + '[value]'" :value="selected ? selected.value : null">
        <input type="hidden" required :name="$attrs.name + '[label]'" :value="selected ? selected.label : null">
        <div class="form-text invalid-feedback" v-cloak>
            {{ errors[0] ? errors[0] : errorFn }}
        </div>
    </validation-provider>
</template>
<script>
import vSelect from 'vue-select'

export default {
    name: 'SearchSelect',
    components: {
        vSelect
    },
    props: {
        value: {
            default: null
        },
        error: {
            default: null
        },
        route: {
            type: String
        },
        translations: {
            type: Object,
            default: null
        },
        initialOptions: {
            type: Array,
            default: () => { return [] }
        }
    },

    data() {
        return {
            options: this.initialOptions,
            selected: this.value,
            errorFn: this.error
        }
    },

    mounted() {
        if (this.value) {
            this.syncSelected()
        }
    },

    watch: {
        value() {
            if (this.value) {
                this.syncSelected()
            }
            this.errorFn = null
        },

        route() {
            this.value = null
            this.syncSelected()
            this.onSearch(null, () => true)
        }
    },

    methods: {
        onSearch(search, loading) {
            const params = {
                name: search
            }
            this.fetchOptions(params, loading)
        },

        fetchOptions(params, loading) {
            if (this.route) {
                loading(true)
                window.axios.get('/' + this.route, {
                    params
                }).then(response => {
                    this.options = response.data
                    loading(false)
                })
            }
        },

        syncSelected() {
            this.selected = this.value
        },

        onChange(selected) {
            this.$emit('input', selected)
        }
    }
}
</script>
