import Vue from 'vue'
import InvoiceForm from "./components/InvoiceForm";
import notifications from "./notifications";

window.Vue = Vue

require('./bootstrap');
require('./validations');

import SearchSelect from './components/SearchSelect'
import Input from './components/inputs/Input'

Vue.component('search-select', SearchSelect)
Vue.component('invoice-form', InvoiceForm)
Vue.component('v-input', Input)

import VueLoading from 'vuejs-loading-plugin'

Vue.use(VueLoading, {
    text: App.translations.loading,
})

new Vue({
    el: '#app',
    name: 'App',
    mixins: [notifications],
})

